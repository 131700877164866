const voidEventListener = (): void => {};

export class Util {
    static _supportsPassive: boolean | undefined;

    /**
     * Detect if the browser support passive event listeners.
     *
     * Feature detection code from documentation:
     * https://github.com/WICG/EventListenerOptions/blob/gh-pages/explainer.md
     */
    supportPassiveEventListeners() {
        if (Util._supportsPassive === undefined) {
            Util._supportsPassive = false;
            try {
                var opts = Object.defineProperty({}, 'passive', {
                    get: function () {
                        Util._supportsPassive = true;
                    },
                });
                // Typescript complains if we use null instead of voidEventListener here
                window.addEventListener('testPassive', voidEventListener, opts);
                window.removeEventListener('testPassive', voidEventListener, opts);
            } catch (e) {}
        }
        return Util._supportsPassive;
    }
}

export const util = new Util();
