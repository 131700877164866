import { rojoCustomElement } from '../../lib/dependencies';
import { util, Util } from '../../lib/util';

import './styles.css';

/***
 * RojoElement is an abstract base class akin to PhoenixElement
 * in the tempest-phoenix repository.
 */
export class RojoElement extends HTMLElement {
    getCookieValue(name: string): string {
        const cookie = document.cookie.split('; ').find((row) => row.startsWith(`${name}=`));
        if (cookie) {
            // Value may contain equals sign, we only want to split on the first
            return cookie.split(/=(.+)$/)[1];
        }
        return '';
    }

    emitEvent(eventName: string, value: string, bubbles = true) {
        const event = new CustomEvent(eventName, {
            bubbles,
            detail: {
                value,
            },
        });
        window.dispatchEvent(event);
    }

    connectedCallback() {
        // Nothing to do yet
    }

    disconnectedCallback() {
        // Nothing to do yet
    }
}

/*********
 * RojoPage
 *
 * Following the pattern of PhoenixPage in the tempest-phoenix repository,
 * this custom element is a vehicle for shared pieces of code in the form
 * of static methods. (Maybe eventually it will include page-level configuration
 * data as the Phoenix version does.)
 */

class RojoPage extends RojoElement {
    /**
     * Expose the util module.
     */
    static get util(): Util {
        return util;
    }

    /**
     * Expose the RojoElement base class.
     */
    static get RojoElement() {
        return RojoElement;
    }
}

rojoCustomElement('rojo-page', RojoPage);
