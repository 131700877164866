import { Util } from './util';
import { RojoElement } from '../webcomponents/page/main';

/*****
 * This file was originally copied from htdocs/bootscripts/lib/dependencies.js
 * in the tempest-phoenix repo.
 ****/

/**
 * Wait for a Web Component to be defined and return its class.
 *
 * @param {string} dependencyName - custom element tag name.
 * @returns {Promise} - Promise of class corresponding to the custom element.
 *
 * Note: `whenDefined` does not just wait for the Javascript file to be downloaded
 * and executed, it also waits for the upgrade of the corresponding elements.
 */

export interface RojoCustomElementConstructor extends CustomElementConstructor {
    RojoElement: typeof RojoElement;
    util?: Util;
}

const getDependencyClass = (dependencyName: string): Promise<CustomElementConstructor> => {
    if (!dependencyName) {
        throw new Error(dependencyName + ' required');
    }
    return window.customElements.whenDefined(dependencyName).then(() => {
        const cec = window.customElements.get(dependencyName);
        if (!cec) {
            // Should not happen in practice
            throw new Error('Defined custom element is not defined');
        }
        return cec;
    });
};

export const getRojoDependencyClass = (dependencyName: string): Promise<RojoCustomElementConstructor> => {
    return getDependencyClass(dependencyName).then((cec: any) => {
        if ('undefined' === typeof cec.RojoElement) {
            throw new Error(dependencyName + ' is missing RojoElement');
        }
        return cec as RojoCustomElementConstructor;
    });
};

/**
 * Wait for the Web Component to be defined and return the first corresponding element.
 *
 * @param {string} dependencyName - custom element tag name.
 * @param {Element | Document} baseElement - element to run querySelector on, defaults to document.
 * @returns {Promise} - Promise of the first Element corresponding to the query.
 */
export const getFirstDependencyElement = (
    dependencyName: string,
    baseElement: Element | Document
): Promise<Element> => {
    if (!dependencyName) {
        throw new Error('dependencyName required');
    }
    if (!baseElement) {
        baseElement = document;
    }
    return window.customElements.whenDefined(dependencyName).then(() => {
        const element = baseElement.querySelector(dependencyName);
        if (!element) {
            throw new Error(dependencyName + ' element cannot be found');
        }
        return element;
    });
};

export const rojoCustomElement = (tagName: string, Class: CustomElementConstructor): void => {
    if (!window.customElements.get(tagName)) {
        window.customElements.define(tagName, Class);
    }
};
